let eventSource = null;
export default {
  eventSource: eventSource,
  start() {
    try {
      eventSource = new EventSource(`https://dev-api.mr-green.ch/events`);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return eventSource;
  },
  stop() {
    eventSource.close();
  },
};
