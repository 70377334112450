import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

const app = createApp(App);

app.use(PrimeVue).use(pinia).use(router).use(ToastService).mount("#app");
