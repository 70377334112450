// Auth
import axiosInstance from "@/api/apiConfig";

export const loginRequest = async (body) => {
  try {
    const { token, user } = await axiosInstance.post("/login", body);
    localStorage.setItem("token", token);
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return user;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const resetEmailPasswordRequest = async (body) => {
  try {
    return await axiosInstance.post("/reset/password/send", body);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const sendNewPasswordRequest = async (body) => {
  try {
    await axiosInstance.post("/reset/password", body);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const logoutRequest = async () => {
  await axiosInstance.post("/logout");
};
