import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store";

const AuthLayout = () => import("@/views/auth/AuthLayout");
const Login = () => import("@/views/auth/Login");
const ResetPass = () => import("@/views/auth/ResetPass");
const NewPass = () => import("@/views/auth/NewPass");

const AdminLayout = () => import("@/views/AdminLayout");
const Invoices = () => import("@/views/Invoices");
const InvoicesShopify = () => import("@/views/InvoicesShopify");
const Payments = () => import("@/views/Payments");
const ScheduleSms = () => import("@/views/ScheduleSms");

const Profile = () => import("@/views/Profile");

const routes = [
  {
    path: "/",
    name: "AuthLayout",
    redirect: { name: "Login" },
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "password/reset",
        name: "ResetPass",
        component: ResetPass,
      },
      {
        path: "password/reset&:token&:email",
        // path: "password/reset/token=:token&email=:email",
        name: "NewPass",
        component: NewPass,
      },
    ],
  },
  {
    path: "/",
    name: "AdminLayout",
    redirect: { name: "Invoices" },
    component: AdminLayout,
    beforeEnter: async (to, from, next) => {
      const $userStore = useUserStore();
      if (!$userStore.isAuthenticated) {
        localStorage.getItem("token") && (await $userStore.getUserData());
      }
      if ($userStore.isAuthenticated) {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    children: [
      {
        path: "invoices-list",
        name: "Invoices",
        component: Invoices,
      },
      {
        path: "shopify-invoices-list",
        name: "ShopifyInvoices",
        component: InvoicesShopify,
        meta: {
          title: "Shopify Invoices",
        },
      },
      {
        path: "payments-list",
        name: "Payments",
        component: Payments,
      },
      {
        path: "schedule-sms",
        name: "ScheduleSms",
        component: ScheduleSms,
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
