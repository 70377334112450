import axiosInstance from "@/api/apiConfig";

// User
export const getUserDataRequest = async () => {
  try {
    return await axiosInstance.post("/account");
  } catch (e) {
    return Promise.reject(e);
  }
};
export const editProfileRequest = async (body) => {
  try {
    await axiosInstance.post("info/new", body);
  } catch (e) {
    return Promise.reject(e);
  }
};

// Upload XML
export const uploadXmlRequest = async (formData) => {
  try {
    return await axiosInstance.post("upload-xml", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
